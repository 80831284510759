
import { defineComponent, ref, reactive, toRefs, onMounted, watch } from 'vue';
import { IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue';
import PersonaPages from '@/components/pages/Pages.vue';
import PersonaError from '@/components/error/Error.vue';
import PersonaCoverLayout from '@/components/layout/cover/CoverLayout.vue';
import { useRoute } from "vue-router";
// @ts-ignore
import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';
import PersonaCoverPage from '@/components/pages/cover-page/CoverPage.vue';
import PersonaContentPage from '@/components/pages/content-page/ContentPage.vue';
import PersonaFormPage from '@/components/pages/form-page/FormPage.vue';
import TopicService from '@/services/topic.service';
import {Page} from '@/models/page.model';
import SessionService from '@/services/session.service';
import useEmitter from '@/composables/useEmitter';
import {EVENTS} from '@/models/app.model';
import AnalyticsService from '@/services/analytics.service';

export default defineComponent({
    name: 'TabsPage',
    components: {
        PersonaCoverLayout,
        PersonaCoverPage,
        PersonaFormPage,
        PersonaContentPage,
        PersonaPages,
        PersonaError,
        IonSegment,
        IonSegmentButton,
        IonLabel,
        Tabs,
        Tab,
        TabPanels,
        TabPanel
    },
    data() {
        return {
            currentPath: this.$route.path,
        };
    },
    setup()
    {
        const route = useRoute();
        const state = reactive({
            selectedTab: 0
        });

        const topic = ref(null);
        onMounted(async () => {
            topic.value = await TopicService.getTopicByPath(route.path);

            // check if another tab then the first tab should be opened
            // handles the first call
            const queryTab = route.query.tab;
            if (queryTab)
            {
                const selectedTab = TopicService.getTabIndex(topic!.value, queryTab);
                if (selectedTab >= 0)
                {
                    state.selectedTab = selectedTab;
                    // console.log('[TabsPage::onMounted] set selectedTab', state.selectedTab);
                }
            }
        });

        const getActive = ((tab: any) =>
        {
            return state.selectedTab === tab;
        });

        const getFirst = ((index: number, page: Page) =>
        {
            // @ts-ignore Property 'pages' does not exist on type 'never'
            const pages = topic.value!.pages;
            if (pages[0].layout === 'cover')
            {
                const firstAfterCoverPage = pages[1];
                return firstAfterCoverPage === page;
            }

            return index === 0;
        });

        const onTabChanged = ((newTabIndex: number) =>
        {
            // console.log('[TabsPage] onTabChanged', newTabIndex);

            // cover is absolute, so we need to hide it, because it would overlay other elements
            const ele = document.getElementsByClassName('persona__cover-layout__cover');
            if (newTabIndex > 0)
            {
                setTimeout(() => {
                    Array.from(ele).forEach((el) => {
                        el.classList.add('hidden');
                    });
                }, 300);
            }
            else {
                Array.from(ele).forEach((el) => {
                    el.classList.remove('hidden');
                });
            }

            AnalyticsService.trackTabChange(topic.value!, newTabIndex);
        });

        const hideFooter = ((topic?) => {
            return false;
        });

        return { route, topic, hideFooter, getActive, onTabChanged, getFirst, ...toRefs(state) }
    },
    watch: {
        // checks if topic has changed or a tab should be opened
        // handles all following calls, not the initial one
        '$route' (to, from)
        {
            // console.log('[TabsPage] $route', to, from);

            // a tab should be opened
            if (to.query && to.query.tab)
            {
                // console.log('[TabsPage] $route to', to);

                // check if another tab then the first tab should be opened
                const queryTab = to.query.tab;
                if (queryTab)
                {
                    // if to topic is not the same as the current topic, we need to update the topic and the tab
                    // @ts-ignore
                    if (to.params.id !== this.topic.path)
                    {
                        TopicService.getTopicByPath(to.params.id).then((topic) => {

                            SessionService.setCurrentTopic(topic);

                            this.topic = topic;

                            // console.log('[TabsPage::$route] set new topic 1', topic);

                            const emitter = useEmitter();
                            emitter.emit(EVENTS.TOPIC_CHANGED, topic);

                            const selectedTab = TopicService.getTabIndex(this.topic, queryTab);
                            if (selectedTab >= 0)
                            {
                                this.selectedTab = selectedTab;
                                // console.log('[TabsPage::$route] set selectedTab 1', this.selectedTab);
                            }
                        });
                    }
                    // if not, we only need to update the selected tab
                    else{
                        const selectedTab = TopicService.getTabIndex(this.topic, queryTab);
                        if (selectedTab >= 0)
                        {
                            this.selectedTab = selectedTab;
                            // console.log('[TabsPage::$route] set selectedTab 2', this.selectedTab);
                        }
                    }
                }
            }
            // another topic should be opened
            else if (to.name !== 'Dashboard' && to.params.id)
            {
                TopicService.getTopicByPath(to.params.id).then((topic) => {
                    if (topic)
                    {
                        SessionService.setCurrentTopic(topic);

                        this.topic = topic;
                        this.selectedTab = 0;

                        // console.log('[TabsPage::$route] set new topic 2', topic);

                        const emitter = useEmitter();
                        emitter.emit(EVENTS.TOPIC_CHANGED, topic);
                    }
                })
            }
        }
    }
});
