
import {defineComponent, ref, toRefs} from 'vue';
import PersonaHeader from '@/components/header/Header.vue';
import {IonRow, IonCol} from '@ionic/vue';
import {Topic} from '@/models/topic.model';
import PersonaAsset from '@/components/asset/Asset.vue';
import {Page} from '@/models/page.model';
import UtilityService from '@/services/utility.service';
import useEmitter from '@/composables/useEmitter';
import {EVENTS} from '@/models/app.model';

export default defineComponent({
    name: 'CoverPage',
    components: {
        PersonaHeader,
        PersonaAsset,
        IonRow,
        IonCol
    },
    props: {
        topic: {
            type: Topic,
            required: false
        },
        page: {
            type: Page,
            required: true
        }
    },
    async setup(props)
    {
        const { topic, page } = toRefs(props);

        const title = ref(page.value.title);

        const hyphenTitle = () => {
            UtilityService.hyphen(page.value.title).then((hyphen) => {
                title.value = hyphen;
            });
        }
        hyphenTitle();

        const getPageCover = (topic) => {
            return topic ? topic.cover.url : '/assets/content/placeholder.png';
        }

        // update page header title if topic changes
        const emitter = useEmitter();
        emitter.on(EVENTS.TOPIC_CHANGED, (newTopic) => {
            // console.log('[CoverPage] topic changed', newTopic);
            UtilityService.hyphen(newTopic.title).then((hyphen) => {
                title.value = hyphen;
                // console.log('[CoverPage] new title', hyphen);
                UtilityService.forceUpdate();
            });
        });

        return { getPageCover, title };
    }
});
