
import {defineComponent, reactive, ref, toRefs} from 'vue';
import TopicService from '@/services/topic.service';
import PersonaCoverPage from '@/components/pages/cover-page/CoverPage.vue';
import PersonaError from '@/components/error/Error.vue';
// @ts-ignore
import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';

/**
 * @deprecated
 */
export default defineComponent({
    name: 'Pages',
    components: {
        PersonaCoverPage,
        PersonaError,
        TabPanels,
        TabPanel
    },
    props: {
        path: {
            type: String,
            required: true
        },
        selectedTabIndex: {
            type: Number,
            default: 0
        }
    },
    async setup(props, {emit}) {

        const error = ref(null);
        const topic = ref(null);

        try {
            topic.value = await TopicService.getTopicByPath(props.path);
            emit('onTopicLoaded', topic.value);

            console.log('[Pages] topic', topic)
        } catch (e){
            error.value = e;
        }


        const state = reactive({
            selectedTab: props.selectedTabIndex
        });

        function getActive(tab: any) {
            return state.selectedTab === tab;
        }

        return { topic, error, getActive, ...toRefs(state) }
    },
    methods: {
        onTabChanged(newTabIndex: number)
        {
            console.log('[Pages] onTabChanged', newTabIndex);
        }
    }
});
