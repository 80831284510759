<template>

    <ion-page>

        <ion-header :translucent="translucent" :class="{'ion-no-border': hideHeaderBorder}">
            <ion-toolbar color="transparent">
                <ion-buttons slot="start" >
<!--                    <ion-back-button color="text-color-cover" :default-href="pageDefaultBackLink" :text="pageDefaultBackTitle"></ion-back-button>-->
                </ion-buttons>
                <ion-title>{{ pageTitle }}</ion-title>
                <ion-buttons slot="end">
                    <slot name="header-buttons-end"></slot>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="fullscreen" :class="{'ion-padding': contentPadding}">
            <div class="container">
                <slot></slot>
            </div>
<!--            <div class="persona__cover-layout__cover" v-if="pageCover">-->
<!--                <persona-asset :asset="{url: pageCover}" :as-background="true"></persona-asset>-->
<!--            </div>-->
        </ion-content>

        <ion-footer color="white" v-if="!hideFooter">
            <ion-grid>
                <ion-row class="ion-align-items-center ion-justify-content-center">
                    <ion-col>
                        <slot name="footer"></slot>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-footer>

    </ion-page>

</template>

<script>
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import PersonaAsset from '@/components/asset/Asset.vue'

export default defineComponent({
    name: 'CoverLayout',
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonBackButton,
        IonRow,
        IonCol,
        PersonaAsset
    },
    props: {
        pageTitle: {
            type: String,
            required: false
        },
        pageCover: {
            type: String,
            required: false
        },
        pageDefaultBackLink: {
            type: String,
            required: false
        },
        pageDefaultBackTitle: {
            type: String,
            required: false
        },
        fullscreen: {
            type: Boolean,
            default: true
        },
        translucent: {
            type: Boolean,
            default: false
        },
        hideHeaderBorder: {
            type: Boolean,
            default: true
        },
        contentPadding: {
            type: Boolean,
            default: true
        },
        hideFooter: {
            type: Boolean,
            default: false
        }
    }
});
</script>

<style lang="scss" src="./CoverLayout.scss"></style>
