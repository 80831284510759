
import {defineComponent, toRefs} from 'vue';
import {Topic} from '@/models/topic.model';
import {Page} from '@/models/page.model';
import PersonaContent from '@/components/content/Content.vue';

export default defineComponent({
    name: 'ContentPage',
    components: {
        PersonaContent
    },
    props: {
        topic: {
            type: Topic,
            required: false
        },
        page: {
            type: Page,
            required: true
        },
        displayHeader: {
            type: Boolean,
            default: true
        }
    }
});
